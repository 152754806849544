import { Button } from "../../../models/Button";
import { concatClasses } from "../../../utils/view/Concatinator";
import styles from "./Secondary.module.css";

const Secondary: typeof Button.Secondary = ({
  className,
  children,
  icon: Icon,
  animateIcon,
  iconSize,
  ...props
}) => {
  return (
    <button className={concatClasses(styles.Body, className)} {...props}>
      {Icon ? (
        <Icon
          className={concatClasses(
            styles.Icon,
            animateIcon === true && styles.AnimateIcon,
            iconSize === "lg"
              ? styles.Big
              : iconSize === "md"
              ? styles.Mid
              : undefined
          )}
        />
      ) : (
        children
      )}
    </button>
  );
};

export default Secondary;
