import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Card } from '../../../models/Card';
import { Heading } from '../../../models/Heading';
import Section from '../../ui/Section/Section';
import styles from './Portfolio.module.css';
import { GlobalContext } from '../../../context/GlobalContext';
import { Button } from '../../../models/Button';
import AudioPlayer from '../../../components/Audio/Player/Player';
import { baseImageUrl, PhosphorIcon } from '../../../global';
import { iconMapperMultiple } from '../../../utils/view/IconMapper';
import { WorkCategoryTypes } from '../../../static/entityTypes';
import { mapLocalizations } from '../../../utils/view/MapLocale';
import { locales } from '../../../locales/locales';
import { applyAnimation } from '../../../utils/view/ApplyAnimation';
import { useAudioPlayerContext } from '../../../context/AudioPlayerContext';

const Portfolio = () => {
  const { data, currentLang } = useContext(GlobalContext)!;
  const { currentTrackIndex } = useAudioPlayerContext();
  const { workCategories, languages } = data!;

  const { setModalBody } = useContext(GlobalContext)!;
  const [tab, setTab] = useState<number>(0);

  const tabLinks = [
    {
      text: locales[currentLang].Sections.Portfolio.categories.All,
    },
    ...workCategories
      ?.map((x) =>
        mapLocalizations({
          entity: x,
          languages,
          locale: currentLang,
        })
      )
      ?.map((x) => ({ text: x.title })),
  ];

  const [icons, setIcons] = useState<{ iconName: string; icon: PhosphorIcon }[] | undefined>();

  useEffect(() => {
    !icons && iconMapperMultiple(workCategories.flatMap((x) => x.iconName)).then((x) => setIcons(x));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [icons]);

  const cardMapper = useCallback(
    (
      workItem: Work.Item & {
        category: WorkCategory.Item;
      },
      index: number
    ) => {
      const { category, title, contentUrl, id, imageUrl } = mapLocalizations({
        entity: workItem,
        languages,
        locale: currentLang,
      });

      const { title: categoryTitle } = mapLocalizations({
        entity: workItem,
        languages,
        locale: currentLang,
      });

      let props: {
        icon: PhosphorIcon;
        subtitle: string;
        children?: ReactNode;
        onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
      } = {
        subtitle: categoryTitle,
        icon: icons!.find((i) => i.iconName === category.iconName)!.icon,
      };
      switch (category.workCategoryType) {
        case WorkCategoryTypes.Image:
          props.onClick = () =>
            setModalBody({
              content: <img alt={title} src={baseImageUrl + contentUrl} className={styles.Image} />,
              title,
              subtitle: categoryTitle,
            });
          break;
        case WorkCategoryTypes.Music:
          props.children = <AudioPlayer trackIndex={index} audioSrc={baseImageUrl + contentUrl} />;
          break;
        case WorkCategoryTypes.Youtube:
          props.onClick = () =>
            setModalBody({
              content: (
                <iframe
                  className={styles.VideoFrame}
                  src={`https://www.youtube.com/embed/${contentUrl}?autoplay=1`}
                  frameBorder="0"
                  allowFullScreen={undefined}
                  allow="autoplay"
                  title="Yellow and Blue Striped"
                />
              ),
              title,
              subtitle: categoryTitle,
            });
          break;
        case WorkCategoryTypes.Link:
          props.onClick = () => window.open(contentUrl);
          break;
      }

      return (
        <Card.Image
          active={category.workCategoryType === WorkCategoryTypes.Music && index === currentTrackIndex}
          key={id}
          className={styles.AnimateCard}
          imageUrl={baseImageUrl + imageUrl}
          title={title}
          {...props}
        />
      );
    },
    [currentLang, currentTrackIndex, icons, languages, setModalBody]
  );

  return (
    <Section id="portfolio">
      <Heading.Primary title={locales[currentLang].Sections.Portfolio.title} subtitle={locales[currentLang].Sections.Portfolio.subtitle} />
      <div data-aos={applyAnimation('fade-up')}>
        <div className={styles.CategoryHeader}>
          {tabLinks?.map((t, i) => (
            <Button.Link className={styles.Link} key={i} onClick={() => setTab(i)} active={tab === i}>
              {t.text}
            </Button.Link>
          ))}
        </div>
        {icons && tab === 0 && (
          <div className={styles.Items}>{workCategories?.map((w) => w?.works?.map((x, i) => cardMapper({ ...x, category: w }, i)))}</div>
        )}
        {icons &&
          workCategories.map(
            (w, i) =>
              tab === i + 1 && (
                <div key={w.id} className={styles.Items}>
                  {w?.works?.map((x, i) => cardMapper({ ...x, category: w }, i))}
                </div>
              )
          )}
      </div>
      {/* {tab === 1 && (
        <div className={styles.Items}>
          <Card.Image
           onClick={() =>
              setModalBody(
                <iframe
                  className={styles.VideoFrame}
                  src="https://www.youtube.com/embed/S4L8T2kFFck?autoplay=1"
                  frameBorder="0"
                  allowFullScreen={undefined}
                  allow="autoplay"
                  title="Yellow and Blue Striped"
                />
                // <AudioPlayer />
              )
            }
            imageUrl={"/images/portfolio/3.jpg"}
            title="White Rose Photo"
            subtitle="content"
            icon={Image}
            onClick={() => setModalBody(<h1>Hello</h1>)}
            className={styles.AnimateCard}
          />
          <Card.Image
            imageUrl={"/images/portfolio/2.jpg"}
            title="Honest Company"
            subtitle="content"
            icon={Image}
            onClick={() => setModalBody(<h1>Hello</h1>)}
            className={styles.AnimateCard}
          />
        </div>
      )} */}
    </Section>
  );
};

export default Portfolio;
