import { PhosphorIcon } from "../../global";

export const iconMapperSingle = async (
  iconName: string
): Promise<PhosphorIcon> => {
  const icons: any = await import("phosphor-react");
  return icons[iconName];
};
export const iconMapperMultiple = async (
  iconNames: string[]
): Promise<{ iconName: string; icon: PhosphorIcon }[]> => {
  const icons: any = await import("phosphor-react");
  const iconsToRender: { iconName: string; icon: PhosphorIcon }[] = [];
  iconNames.forEach((x) => iconsToRender.push({ icon: icons[x], iconName: x }));
  return iconsToRender;
};
