import React, { useContext, useEffect, useState } from 'react';
import Modal from '../../../components/Modal/Modal';
import Preloader from '../../../components/Preloader/Preloader';
import { GlobalContext } from '../../../context/GlobalContext';
import { Navbar } from '../../../models/Navbar';
import { concatClasses } from '../../../utils/view/Concatinator';
import About from '../../elements/About/About';
import CareerSkills from '../../elements/CareerSkills/CareerSkills';
import Contacts from '../../elements/Contacts/Contacts';
import Education from '../../elements/Education/Education';
import Experience from '../../elements/Experience/Experience';
import Home from '../../elements/Home/Home';
import LanguageSkills from '../../elements/LanguageSkills/LanguageSkills';
import Portfolio from '../../elements/Portfolio/Portfolio';
import Reviews from '../../elements/Reviews/Reviews';
import Services from '../../elements/Services/Services';
import Cover from '../Cover/Cover';
import Footer from '../Footer/Footer';
import Main from '../Main/Main';
import styles from './Layout.module.css';
import AOS from 'aos';
import { isDeviceMobile } from '../../../utils/view/DeviceTypeDetector';
import { applyAnimation } from '../../../utils/view/ApplyAnimation';
import { AudioPlayerContextProvider } from '../../../context/AudioPlayerContext';

const Layout: DivElement = ({ className, ...props }) => {
  const mainRef = React.useRef<HTMLDivElement>(null);
  const mainDivRef = React.useRef<HTMLDivElement>(null);
  const coverRef = React.useRef<HTMLDivElement>(null);

  const [mounted, setMounted] = useState<boolean>(false);
  const { data } = useContext(GlobalContext)!;

  useEffect(() => {
    if (data && !mounted) {
      const timeout = setTimeout(() => setMounted(true), 500);
      return () => {
        clearTimeout(timeout);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, mounted]);

  useEffect(() => {
    console.log(isDeviceMobile);
    !isDeviceMobile &&
      AOS.init({
        // once: true,
        duration: 1000,
      });
  }, []);

  return (
    <div
      ref={mainRef}
      className={concatClasses(styles.Body, className)}
      style={
        {
          '--color-accent': data?.theme.accentColor,
          '--color-bg': data?.theme.backgroundColor,
          '--color-content': data?.theme.contentTextColor,
          '--color-heading': data?.theme.headingTextColor,
          '--color-image': data?.theme.imageColor,
          '--color-logo': data?.theme.logoColor,
          '--color-nav': data?.theme.navTextColor,
          '--color-subtitle': data?.theme.subtitleColor,
          '--color-section-border': data?.theme.sectionBorderColor,
          '--color-button-border': data?.theme.buttonBorderColor,
          '--color-footer-border': data?.theme.footerBorderColor,
        } as React.CSSProperties
      }
      {...props}
    >
      {!mounted && <Preloader active={data === undefined} />}
      {data && (
        <>
          <Navbar.Desktop />
          <Cover ref={coverRef} />
          <Main ref={mainDivRef}>
            <Modal mainRef={mainRef} />
            <Home />
            <About />
            {data.experiences?.length > 0 && <Experience />}
            {data.educations?.length > 0 && <Education />}
            {data.services?.length > 0 && <Services />}
            {data.abilities?.length > 0 && <CareerSkills />}
            {data.languageKnowledges?.length > 0 && <LanguageSkills />}
            {data.reviews?.length > 0 && <Reviews />}
            <AudioPlayerContextProvider>{data.workCategories?.length > 0 && <Portfolio />}</AudioPlayerContextProvider>
            <Contacts />
          </Main>
          {mainDivRef.current && (
            <Footer
              data-aos={applyAnimation('fade-left')}
              style={{
                width: mainDivRef.current?.clientWidth
                  ? window.innerWidth - ((mainDivRef.current?.clientWidth ?? 0) + (coverRef.current?.clientWidth ?? 0))
                  : 95,
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Layout;
