import React, { useContext, useEffect, useState } from "react";
import { Card } from "../../../models/Card";
import { Heading } from "../../../models/Heading";
import Section from "../../ui/Section/Section";
import styles from "./Services.module.css";
import { GlobalContext } from "../../../context/GlobalContext";
import { PhosphorIcon } from "../../../global";
import { iconMapperMultiple } from "../../../utils/view/IconMapper";
import { mapLocalizations } from "../../../utils/view/MapLocale";
import { locales } from "../../../locales/locales";
import { applyAnimation } from "../../../utils/view/ApplyAnimation";

const Services = () => {
  const { data, currentLang } = useContext(GlobalContext)!;
  const { services, languages } = data!;

  const [icons, setIcons] = useState<{ iconName: string; icon: PhosphorIcon }[] | undefined>();

  useEffect(() => {
    !icons && iconMapperMultiple(services.flatMap((x) => x.iconName)).then((x) => setIcons(x));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [icons]);

  return (
    <Section id="resume">
      <Heading.Primary
        title={locales[currentLang].Sections.Services.title}
        subtitle={locales[currentLang].Sections.Services.subtitle}
      />
      <div data-aos={applyAnimation("fade-up")} className={styles.Items}>
        {icons &&
          services
            .map((x) =>
              mapLocalizations({
                entity: x,
                languages,
                locale: currentLang,
              })
            )
            .map(({ id, content, iconName, title }) => (
              <Card.Secondary key={id} icon={icons.find((i) => i.iconName === iconName)!.icon} title={title}>
                {content}
              </Card.Secondary>
            ))}
      </div>
    </Section>
  );
};

export default Services;
