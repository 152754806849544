import { Card } from "../../../models/Card";
import { concatClasses } from "../../../utils/view/Concatinator";
import Paragraph from "../../Paragraph/Paragraph";
import styles from "./Step.module.css";

const Step: typeof Card.Step = ({
  className,
  title,
  percentage,
  children,
  ...props
}) => {
  return (
    <div className={concatClasses(styles.Body, className)} {...props}>
      <div className={styles.Title}>{title}</div>
      <Paragraph>{children}</Paragraph>
      {percentage <= 100 && 0 <= percentage && (
        <div className={styles.StepsContainer}>
          {[...Array(Math.ceil(percentage / 10))].map((x, i) => (
            <div
              key={i}
              className={concatClasses(styles.Step, styles.Active)}
            />
          ))}
          {[...Array(Math.floor((100 - percentage) / 10))].map((x, i) => (
            <div key={i} className={styles.Step} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Step;
