import { Card } from "../../../models/Card";
import { concatClasses } from "../../../utils/view/Concatinator";
import Paragraph from "../../Paragraph/Paragraph";
import styles from "./Primary.module.css";

const Primary: typeof Card.Primary = ({
  className,
  title,
  subtitle,
  children,
  ...props
}) => {
  return (
    <div className={concatClasses(styles.Body, className)} {...props}>
      <div className={styles.Subtitle}>
        <span>{subtitle}</span>
      </div>
      <div className={styles.Title}>
        <span>{title}</span>
      </div>
      <div className={styles.Text}>
        <Paragraph>{children}</Paragraph>
      </div>
    </div>
  );
};

export default Primary;
