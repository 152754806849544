import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../../models/Button";
import { concatClasses } from "../../../utils/view/Concatinator";
import styles from "./Footer.module.css";
import { PhosphorIcon } from "../../../global";
import { iconMapperMultiple } from "../../../utils/view/IconMapper";
import { GlobalContext } from "../../../context/GlobalContext";
import { mapLocalizations } from "../../../utils/view/MapLocale";

const Footer: DivElement = ({ className, ...props }) => {
  const { data, currentLang } = useContext(GlobalContext)!;
  const { socialNetworks, languages } = data!;
  const [icons, setIcons] = useState<
    { iconName: string; icon: PhosphorIcon }[] | undefined
  >();

  useEffect(() => {
    !icons &&
      iconMapperMultiple(socialNetworks.flatMap((x) => x.iconName)).then((x) =>
        setIcons(x)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [icons]);
  return (
    <div className={concatClasses(styles.Body, className)} {...props}>
      <div className={styles.Socials}>
        {icons &&
          socialNetworks
            .map((x) =>
              mapLocalizations({
                entity: x,
                languages,
                locale: currentLang,
              })
            )
            .map(({ title, id, url, iconName }) => (
              <Button.Secondary
                key={id}
                title={title}
                onClick={() => window.open(url)}
                iconSize="lg"
                icon={icons!.find((i) => i.iconName === iconName)!.icon}
              />
            ))}
      </div>
    </div>
  );
};

export default Footer;
