import { useRef } from "react";
import { Carousel } from "../../../../models/Carousel";
import { concatClasses } from "../../../../utils/view/Concatinator";
import styles from "./Container.module.css";
import Slider from "react-slick";
import { Settings as CarouselSettings } from "react-slick";
const Container: typeof Carousel.Banner.Container = ({
  className,
  children,
  ...props
}) => {
  const carouselRef = useRef<Slider>(null);

  const settings: CarouselSettings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 10000,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    verticalSwiping: true,
    vertical: true,
    adaptiveHeight: true,
    rtl: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          rtl: false,
          vertical: false,
          verticalSwiping: false,
          fade: true,
          autoplaySpeed: 5000,
        },
      },
    ],
  };

  return (
    <div className={concatClasses(styles.Body, className)} {...props}>
      <Slider className={styles.Slider} ref={carouselRef} {...settings}>
        {children}
      </Slider>
    </div>
  );
};

export default Container;
