import React, { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { locales } from "../../../locales/locales";
import { Card } from "../../../models/Card";
import { Heading } from "../../../models/Heading";
import { mapLocalizations } from "../../../utils/view/MapLocale";
import Section from "../../ui/Section/Section";
import styles from "./LanguageSkills.module.css";
const LanguageSkills = () => {
  const { data, currentLang } = useContext(GlobalContext)!;
  const { languageKnowledges, languages } = data!;

  return (
    <Section id="resume">
      <Heading.Primary
        title={locales[currentLang].Sections.LanguageSkills.title}
        subtitle={locales[currentLang].Sections.LanguageSkills.subtitle}
      />
      <div className={styles.Items}>
        {languageKnowledges
          .map((x) =>
            mapLocalizations({
              entity: x,
              languages,
              locale: currentLang,
            })
          )
          .map(({ id, percentage, title, content }) => (
            <Card.Step key={id} title={title} percentage={percentage}>
              {content}
            </Card.Step>
          ))}
      </div>
    </Section>
  );
};

export default LanguageSkills;
