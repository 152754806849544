import Section from "../../ui/Section/Section";
import styles from "./Home.module.css";
import Typed from "react-typed";
import Paragraph from "../../../components/Paragraph/Paragraph";
import { CaretDown, CaretRight } from "phosphor-react";
import { Button } from "../../../models/Button";
import React, { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { concatClasses } from "../../../utils/view/Concatinator";
import { mapLocalizations } from "../../../utils/view/MapLocale";
import { locales } from "../../../locales/locales";
import { applyAnimation } from "../../../utils/view/ApplyAnimation";

const Home = () => {
  const { activeElement, data, currentLang } = useContext(GlobalContext)!;
  const { languages, general, occupations } = data!;

  const { fullName, content } = mapLocalizations({
    entity: general,
    languages,
    locale: currentLang,
  });

  return (
    <Section id="home" className={styles.Section}>
      <div data-aos={applyAnimation("fade-up")} className={styles.Body}>
        <div className={styles.Heading}>
          <h1 className={styles.Title}>
            <span>{fullName}</span>
          </h1>
          <div className={styles.Subtitle}>
            <Typed
              strings={occupations
                .map((x) =>
                  mapLocalizations({
                    entity: x,
                    languages,
                    locale: currentLang,
                  })
                )
                .map((x) => x.title)}
              typeSpeed={50}
              backSpeed={50}
              loop
            />
          </div>
        </div>
        <Paragraph className={styles.Paragraph}>{content}</Paragraph>
        <div className={styles.ButtonWrapper}>
          <Button.Primary
            onClick={() => document.querySelector("#contacts")?.scrollIntoView({ behavior: "smooth", block: "center" })}
            icon={CaretRight}
          >
            {locales[currentLang].Buttons.ContactMe}
          </Button.Primary>
          <Button.Secondary
            className={concatClasses(
              styles.NavigationButton,
              activeElement === "home" ? styles.NavigationButtonActive : styles.NavigationButtonDeactive
            )}
            onClick={() => document.querySelector("#about")?.scrollIntoView({ behavior: "smooth", block: "center" })}
            animateIcon
            icon={CaretDown}
          />
        </div>
      </div>
    </Section>
  );
};

export default Home;
