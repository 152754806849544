export const mapLocalizations = <
  T extends {
    localizations: L[];
  },
  L extends Localization
>({
  entity,
  languages,
  locale,
}: {
  entity: T;
  languages: Language.Item[];
  locale?: string;
}): T & T["localizations"][0] => {
  const lang = languages.find((x) => x.normalizedName === locale);

  const currentLocalization = entity.localizations.find(
    (x) => x.languageId === lang?.id
  );

  return {
    ...entity,
    ...currentLocalization!,
  };
};
