import { Controller } from "react-hook-form";
import { Form } from "../../../models/Form";
import { concatClasses } from "../../../utils/view/Concatinator";
import ErrorField from "../Error/Error";
import styles from "./Input.module.css";

const Input: typeof Form.Input = ({
  className,
  label,
  name,
  controllerProps,
  ...props
}) => {
  return (
    <div className={styles.Wrapper}>
      {label && <span className={styles.Label}>{label}</span>}
      <Controller
        name={name}
        defaultValue=""
        {...controllerProps}
        render={({ field, fieldState: { error } }) => (
          <>
            <input
              className={concatClasses(styles.Body, className)}
              {...props}
              {...field}
            />
            <ErrorField>{error?.message}</ErrorField>
          </>
        )}
      />
    </div>
  );
};

export default Input;
