import { useContext, useEffect, useRef } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import useOnScreen from "../../../hooks/OnScreen";
import { concatClasses } from "../../../utils/view/Concatinator";
import styles from "./Section.module.css";

const Section: DivElement = ({ className, ...props }) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const { setActiveElement, data } = useContext(GlobalContext)!;
  const isVisible = useOnScreen(sectionRef);

  useEffect(() => {
    if (isVisible) {
      setActiveElement(props.id!);
      document.title = `${data!.general.fullName} ${props.id ? `| ${props.id?.toUpperCase()}` : ``}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  return <section ref={sectionRef} className={concatClasses(styles.Body, className)} {...props} />;
};

export default Section;
