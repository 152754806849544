import BannerContainer from "../components/Carousel/Banner/Container/Container";
import CardContainer from "../components/Carousel/Card/Container/Container";
import Item from "../components/Carousel/Banner/Item/Item";

type CarouselComponent = {
  Banner: {
    Item: DivElement<{ imageUrl: string; imageTitle: string }>;
    Container: DivElement;
  };
  Card: { Container: DivElement };
};

export const Carousel: CarouselComponent = {
  Banner: { Container: BannerContainer, Item },
  Card: { Container: CardContainer },
};
