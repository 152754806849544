import React, { HTMLAttributes } from "react";
import { concatClasses } from "../../utils/view/Concatinator";
import styles from "./Paragraph.module.css";

const Paragraph: React.FC<HTMLAttributes<HTMLParagraphElement>> = ({
  className,
  ...props
}) => {
  return <p className={concatClasses(styles.Body, className)} {...props} />;
};

export default Paragraph;
