import { Card } from "../../../models/Card";
import { concatClasses } from "../../../utils/view/Concatinator";
import styles from "./Secondary.module.css";
import Paragraph from "../../Paragraph/Paragraph";
const Secondary: typeof Card.Secondary = ({
  className,
  title,
  children,
  icon: Icon,
  ...props
}) => {
  return (
    <div className={concatClasses(styles.Body, className)} {...props}>
      <Icon weight="duotone" className={styles.Icon} />
      <div className={styles.Title}>{title}</div>
      <Paragraph>{children}</Paragraph>
    </div>
  );
};

export default Secondary;
