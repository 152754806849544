import axios, { AxiosError, AxiosResponse } from "axios";
import { baseAPIUrl, requestDelay } from "../global";
const sleep = (delay: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
};

const managementAPI = axios.create();

managementAPI.defaults.baseURL = baseAPIUrl;

managementAPI.interceptors.response.use(
  async (response) => {
    await sleep(requestDelay * 1000);
    return response;
  },
  (error: AxiosError) => Promise.reject(error)
);

const responseBody = <T>(response: AxiosResponse<Result<T>>) => response.data;

const requests = {
  get: <T>(url: string) => managementAPI.get<Result<T>>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    managementAPI.post<Result<T>>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) =>
    managementAPI.put<Result<T>>(url, body).then(responseBody),
  del: <T>(url: string) =>
    managementAPI.delete<Result<T>>(url).then(responseBody),
};

const Application = {
  GetHomeData: () => requests.get<HomeDto>("Application"),
  SubmitRequest: (body: ContactRequest.Create) =>
    requests.post<boolean>("Application", body),
};

export const agent = {
  Application,
};
