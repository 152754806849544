import React, { useEffect, useState } from "react";
import { concatClasses } from "../../../utils/view/Concatinator";
import styles from "./Fade.module.css";

const Fade: DivElement<{
  visible: boolean;
  /** Animation duration in milliseconds. */
  animationDuration?: number;
}> = ({
  children,
  visible,
  className,
  animationDuration = 300,
  style,
  ...props
}) => {
  const [show, setShow] = useState(false);

  const handleVisibility = (visible: boolean) => {
    setTimeout(
      () => setShow(visible),
      visible === true ? 0 : animationDuration
    );
  };

  useEffect(() => {
    handleVisibility(visible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return show ? (
    <div
      className={concatClasses(
        className,
        visible ? styles.Visible : styles.Invisible
      )}
      style={{
        ...style,
        animationDuration: `${animationDuration}ms`,
      }}
      {...props}
    >
      {children}
    </div>
  ) : (
    <></>
  );
};

export default Fade;
