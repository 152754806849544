import { useContext } from "react";
import { GlobalContext } from "../../../../context/GlobalContext";
import { Carousel } from "../../../../models/Carousel";
import { concatClasses } from "../../../../utils/view/Concatinator";
import styles from "./Item.module.css";
const Item: typeof Carousel.Banner.Item = ({
  className,
  imageUrl,
  imageTitle,
  style,

  ...props
}) => {
  const { data } = useContext(GlobalContext)!;

  const imageColor = data?.theme.imageColor;
  return (
    <div
      className={concatClasses(styles.Body, className)}
      style={{
        ...style,
        backgroundColor: imageColor ?? undefined,
      }}
      {...props}
    >
      <div
        title={imageTitle}
        className={styles.Image}
        style={{
          backgroundImage: `url(${imageUrl})`,
          ...(imageColor !== null ? { filter: "grayscale(100%)", mixBlendMode: "luminosity" } : {}),
        }}
      />
    </div>
  );
};

export default Item;
