import Container from "../components/Pair/Container/Container";
import Item from "../components/Pair/Item/Item";

type PairComponent = {
  Container: React.FC<React.OlHTMLAttributes<HTMLUListElement>>;
  Item: React.FC<
    React.LiHTMLAttributes<HTMLLIElement> & {
      head: React.ReactNode;
      value: React.ReactNode;
      headClassName?: string | undefined;
    }
  >;
};

export const Pair: PairComponent = {
  Container,
  Item,
};
