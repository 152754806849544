import { useContext } from "react";
import Paragraph from "../../../components/Paragraph/Paragraph";
import { GlobalContext } from "../../../context/GlobalContext";
import { locales } from "../../../locales/locales";
import { Heading } from "../../../models/Heading";
import { Pair } from "../../../models/Pair";
import { applyAnimation } from "../../../utils/view/ApplyAnimation";
import { mapLocalizations } from "../../../utils/view/MapLocale";
import Section from "../../ui/Section/Section";
const About = () => {
  const { data, currentLang } = useContext(GlobalContext)!;
  const { general, keyInformations, languages } = data!;

  const { description } = mapLocalizations({
    entity: general,
    languages,
    locale: currentLang,
  });

  return (
    <Section id="about">
      <Heading.Primary
        title={locales[currentLang].Sections.About.title}
        subtitle={locales[currentLang].Sections.About.subtitle}
      />
      <Paragraph data-aos={applyAnimation("fade-up")}>{description}</Paragraph>
      <Pair.Container>
        {keyInformations
          .map((x) => mapLocalizations({ entity: x, languages, locale: currentLang }))
          .map(({ id, title, subtitle }) => (
            <Pair.Item key={id} head={title} value={subtitle} />
          ))}
      </Pair.Container>
    </Section>
  );
};

export default About;
