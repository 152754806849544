import { ControllerProps } from "react-hook-form/dist/types/controller";
import { FieldValues } from "react-hook-form/dist/types/fields";
import Input from "../components/Form/Input/Input";
import TextArea from "../components/Form/TextArea/TextArea";

type CommonInputProps = {
  label?: React.ReactNode;
  controllerProps?: NonConcreteParams<
    ControllerProps<FieldValues, ControllerProps["name"]>
  >;
  name: string;
};

type FormComponent = {
  Input: React.FC<
    React.InputHTMLAttributes<HTMLInputElement> & CommonInputProps
  >;
  TextArea: React.FC<
    React.TextareaHTMLAttributes<HTMLTextAreaElement> & CommonInputProps
  >;
};

export const Form: FormComponent = {
  Input,
  TextArea,
};
