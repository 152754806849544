import React from "react";
import ReactDOM from "react-dom";
import Layout from "./containers/ui/Layout/Layout";
import "./styles/global.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import { GlobalContextProvider } from "./context/GlobalContext";

ReactDOM.render(
  <React.StrictMode>
    <GlobalContextProvider>
      <Layout
      // style={
      //   {
      //     "--color-accent": "#00bf9f",
      //   } as React.CSSProperties
      // }
      />
    </GlobalContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
