import React, { useContext, useState } from 'react';

interface AudioPlayerContextType {
  currentTrackIndex: number | undefined;
  setCurrentTrackIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const AudioPlayerContext = React.createContext<AudioPlayerContextType | null>(null);

export function AudioPlayerContextProvider({ children }: { children: React.ReactNode }) {
  const [currentTrackIndex, setCurrentTrackIndex] = useState<number | undefined>();

  return <AudioPlayerContext.Provider value={{ currentTrackIndex, setCurrentTrackIndex }} children={children} />;
}

export function useAudioPlayerContext() {
  return useContext(AudioPlayerContext)!;
}
