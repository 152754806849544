import { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { locales } from "../../../locales/locales";
import { Card } from "../../../models/Card";
import { Carousel } from "../../../models/Carousel";
import { Heading } from "../../../models/Heading";
import { mapLocalizations } from "../../../utils/view/MapLocale";
import Section from "../../ui/Section/Section";

const Experience = () => {
  const { data, currentLang } = useContext(GlobalContext)!;
  const { experiences, languages } = data!;

  return (
    <Section id="resume">
      <Heading.Primary
        title={locales[currentLang].Sections.Experience.title}
        subtitle={locales[currentLang].Sections.Experience.subtitle}
      />
      <Carousel.Card.Container>
        {experiences
          .map((x) =>
            mapLocalizations({
              entity: x,
              languages,
              locale: currentLang,
            })
          )
          .map(({ id, subtitle, title, content }) => (
            <Card.Primary key={id} subtitle={subtitle} title={title}>
              {content}
            </Card.Primary>
          ))}
      </Carousel.Card.Container>
    </Section>
  );
};

export default Experience;
