import React from "react";
import { Button } from "../../../models/Button";
import { concatClasses } from "../../../utils/view/Concatinator";
import styles from "./Primary.module.css";

const Primary: typeof Button.Primary = ({
  className,
  children,
  icon: Icon,
  ...props
}) => {
  const childrenMapper = (children?: React.ReactNode | undefined) => {
    if (children && typeof children === "string") {
      const elements: React.ReactElement[] = [];

      for (let index = 0; index < children.length; index++) {
        elements.push(
          <em
            style={{
              transitionDelay: `${0.025 * (index + 1)}s`,
            }}
            className={styles.Letter}
            key={index}
          >
            {children[index]}
          </em>
        );
      }
      return elements;
    }
    return children;
  };

  return (
    <button className={concatClasses(styles.Body, className)} {...props}>
      <span className={styles.AnimatedText}>
        <span className={styles.Text}>{childrenMapper(children)}</span>
      </span>
      {Icon && <Icon className={styles.Icon} />}
    </button>
  );
};

export default Primary;
