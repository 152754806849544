import { Button } from "../../../models/Button";
import { Form } from "../../../models/Form";
import { Heading } from "../../../models/Heading";
import Section from "../../ui/Section/Section";
import styles from "./Contacts.module.css";
import { CaretRight } from "phosphor-react";
import { Pair } from "../../../models/Pair";
import { useContext, useState } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { mapLocalizations } from "../../../utils/view/MapLocale";
import { locales } from "../../../locales/locales";
import { useForm } from "react-hook-form";
import { agent } from "../../../api/agent";
import Fade from "../../../components/Animation/Fade/Fade";

const Contact = () => {
  const { data, currentLang } = useContext(GlobalContext)!;
  const { keyInformations, general, languages } = data!;

  const { occupation, fullName } = mapLocalizations({
    entity: general,
    languages,
    locale: currentLang,
  });

  const [message, setMessage] = useState<string>();

  const { control, handleSubmit, reset } = useForm();

  const submitHandler = async (values: ContactRequest.Create) => {
    try {
      const result = await agent.Application.SubmitRequest(values);
      result.isSuccess && setMessage(locales[currentLang].Form.Success);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Section id="contacts">
      <Heading.Primary
        title={locales[currentLang].Sections.Contact.title}
        subtitle={locales[currentLang].Sections.Contact.subtitle}
      />
      <form
        onSubmit={handleSubmit(submitHandler as any)}
        className={styles.Form}
      >
        <div className={styles.Left}>
          <Form.Input
            placeholder="ej.: Genoveva Lian Hullt"
            label={locales[currentLang].Form.Fields.FullName}
            name="fullName"
            controllerProps={{
              control: control,
              rules: {
                required: {
                  value: true,
                  message: locales[currentLang].Form.Validation.Required,
                },
              },
            }}
          />
          <Form.Input
            placeholder="example@domain.com"
            label={locales[currentLang].Form.Fields.EmailAddress}
            name="email"
            controllerProps={{
              control: control,
              rules: {
                required: {
                  value: true,
                  message: locales[currentLang].Form.Validation.Required,
                },
                pattern: {
                  value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                  message: locales[currentLang].Form.Validation.Email,
                },
              },
            }}
          />
          <Form.TextArea
            placeholder={locales[currentLang].Form.Fields.ToWrite}
            rows={5}
            label={locales[currentLang].Form.Fields.Message}
            name="content"
            controllerProps={{
              control: control,
            }}
          />
          <Button.Primary
            className={styles.Button}
            disabled={!!message}
            icon={!message ? CaretRight : undefined}
          >
            {!message && (
              <Fade visible={true}>
                {locales[currentLang].Buttons.SendMessage}
              </Fade>
            )}
            <Fade visible={!!message}>{message}</Fade>
          </Button.Primary>
        </div>
        <div className={styles.Right}>
          <div className={styles.Heading}>
            <h3 className={styles.Title}>{fullName}</h3>
            <h5 className={styles.Subtitle}>{occupation}</h5>
          </div>
          <Pair.Container className={styles.Pair}>
            {keyInformations
              .map((x) =>
                mapLocalizations({
                  entity: x,
                  languages,
                  locale: currentLang,
                })
              )
              .map(({ title, subtitle, id }) => (
                <Pair.Item
                  className={styles.PairItem}
                  key={id}
                  head={title}
                  value={subtitle}
                />
              ))}
          </Pair.Container>
          <div className={styles.SignatureWrapper}>
            <span className={styles.Signature}>{fullName}</span>
          </div>
        </div>
      </form>
    </Section>
  );
};

export default Contact;
