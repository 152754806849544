import React from "react";
import { Pair } from "../../../models/Pair";
import { concatClasses } from "../../../utils/view/Concatinator";
import styles from "./Container.module.css";

const Container: typeof Pair.Container = ({ className, ...props }) => {
  return <ul className={concatClasses(styles.Body, className)} {...props} />;
};

export default Container;
