import React, { AnchorHTMLAttributes } from "react";
import { concatClasses } from "../../utils/view/Concatinator";
import styles from "./Anchor.module.css";
const Anchor: React.FC<
  AnchorHTMLAttributes<HTMLAnchorElement> & {
    active?: boolean | undefined;
  }
> = ({ className, children, active, ...props }) => {
  const childrenMapper = (children?: React.ReactNode | undefined) => {
    if (children && typeof children === "string") {
      const elements: React.ReactElement[] = [];

      for (let index = 0; index < children.length; index++) {
        elements.push(
          <em
            style={{
              transitionDelay: `${0.025 * (index + 1)}s`,
            }}
            className={styles.Letter}
            key={index}
          >
            {children[index]}
          </em>
        );
      }
      return elements;
    }
    return children;
  };

  return (
    <a
      className={concatClasses(
        styles.Body,
        className,
        active === true ? styles.Active : undefined
      )}
      {...props}
    >
      <span className={styles.AnimatedText}>
        <span className={styles.Text}>{childrenMapper(children)}</span>
      </span>
    </a>
  );
};

export default Anchor;
