import React, { ReactNode, useRef } from "react";
import { Carousel } from "../../../../models/Carousel";
import Slider from "react-slick";
import { Settings as CarouselSettings } from "react-slick";
import styles from "./Container.module.css";
import { Button } from "../../../../models/Button";
import { CaretLeft, CaretRight } from "phosphor-react";

const Container: typeof Carousel.Card.Container = ({ children, ...props }) => {
  const settings: CarouselSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const carouselRef = useRef<Slider>(null);

  const mapElements = (children?: React.ReactNode | undefined) => {
    return React.Children.map<ReactNode, ReactNode>(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          className: styles.Card,
        } as any);
      }
    });
  };

  return (
    <div className={styles.Body} {...props}>
      <Slider ref={carouselRef} {...settings}>
        {mapElements(children)}
      </Slider>
      <div className={styles.Navigation}>
        <Button.Secondary
          onClick={() => carouselRef.current?.slickPrev()}
          className={styles.NavLeft}
          icon={CaretLeft}
        />
        <Button.Secondary
          onClick={() => carouselRef.current?.slickNext()}
          className={styles.NavRight}
          icon={CaretRight}
        />
      </div>
    </div>
  );
};

export default Container;
