import { Card } from '../../../models/Card';
import { concatClasses } from '../../../utils/view/Concatinator';
import styles from './Image.module.css';
const Image: typeof Card.Image = ({ className, title, subtitle, imageUrl, icon: Icon, active, children, ...props }) => {
  return (
    <div className={concatClasses(styles.Body, active === true && styles.Active, className)} {...props}>
      <div className={styles.ImageWrapper}>
        <img className={styles.Image} alt="" src={imageUrl} />
        <div className={styles.Info}>
          <span className={styles.Centrized}>
            <Icon weight="duotone" className={styles.Icon} />
            {children}
          </span>
        </div>
      </div>
      <div className={styles.Description}>
        <div className={styles.Subtitle}>
          <span>{subtitle}</span>
        </div>
        <div className={styles.Title}>
          <span>{title}</span>
        </div>
      </div>
    </div>
  );
};

export default Image;
