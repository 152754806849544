import React, { useContext, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { concatClasses } from "../../utils/view/Concatinator";
import Fade from "../Animation/Fade/Fade";
import styles from "./Dropdown.module.css";

const Dropdown: DivElement<{
  languages: Language.Item[];
}> = ({ className, children, languages, ...props }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { currentLang, handleLanguageChange } = useContext(GlobalContext)!;
  const animationProps: {
    onMouseLeave: React.HtmlHTMLAttributes<HTMLDivElement>["onMouseLeave"];
    onMouseEnter: React.HtmlHTMLAttributes<HTMLDivElement>["onMouseEnter"];
  } = {
    onMouseLeave: () => visible && setVisible(false),
    onMouseEnter: () => setVisible(true),
  };
  return (
    <div className={concatClasses(styles.Body, className)} {...props}>
      <div {...animationProps} className={styles.Current}>
        {currentLang.toLocaleUpperCase()}
      </div>
      <Fade {...animationProps} visible={visible}>
        <div className={styles.Content}>
          <ul className={concatClasses(styles.ItemCollection)}>
            {languages
              .filter((x) => x.normalizedName !== currentLang)
              .map(({ id, normalizedName }) => (
                <li
                  key={id}
                  onClick={() => handleLanguageChange(normalizedName)}
                  className={styles.Item}
                >
                  {normalizedName.toUpperCase()}
                </li>
              ))}
          </ul>
        </div>
      </Fade>
    </div>
  );
};
export default Dropdown;
