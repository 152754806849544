import Primary from "../components/Heading/Primary/Primary";
import Secondary from "../components/Heading/Secondary/Secondary";

type HeadingComponent = {
  Primary: DivElement<{
    title: React.ReactNode;
    subtitle: React.ReactNode;
  }>;
  Secondary: DivElement<{
    title: React.ReactNode;
    subtitle: React.ReactNode;
  }>;
};

export const Heading: HeadingComponent = {
  Primary,
  Secondary,
};
