import { GlobalContext } from "../../../context/GlobalContext";
import { Carousel } from "../../../models/Carousel";
import { concatClasses } from "../../../utils/view/Concatinator";
import { HtmlHTMLAttributes, useContext } from "react";
import styles from "./Cover.module.css";
import { baseImageUrl } from "../../../global";
import { mapLocalizations } from "../../../utils/view/MapLocale";
import React from "react";
import { applyAnimation } from "../../../utils/view/ApplyAnimation";
const Cover = React.forwardRef<HTMLDivElement, HtmlHTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => {
  const { data, currentLang } = useContext(GlobalContext)!;
  const { slides, languages } = data!;
  return (
    <div ref={ref} className={concatClasses(styles.Body, className)} {...props}>
      <div data-aos={applyAnimation("fade-right")} className={styles.Wrapper}>
        <Carousel.Banner.Container>
          {slides
            .map((x) => mapLocalizations({ entity: x, languages, locale: currentLang }))
            .map(({ imageUrl, id, title }) => (
              <Carousel.Banner.Item key={id} imageTitle={title} imageUrl={baseImageUrl + imageUrl} />
            ))}
        </Carousel.Banner.Container>
      </div>
    </div>
  );
});

export default Cover;
