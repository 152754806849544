import React from "react";
import { concatClasses } from "../../../utils/view/Concatinator";
import styles from "./Main.module.css";
const Main = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={concatClasses(styles.Body, className)}
      {...props}
    />
  );
});

export default Main;
