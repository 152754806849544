import { Card } from "../../../models/Card";
import { concatClasses } from "../../../utils/view/Concatinator";
import Paragraph from "../../Paragraph/Paragraph";
import styles from "./Avatar.module.css";

const Avatar: typeof Card.Avatar = ({
  className,
  title,
  subtitle,
  children,
  imageUrl,
  ...props
}) => {
  return (
    <div className={concatClasses(styles.Body, className)} {...props}>
      <div className={styles.ImageWrapper}>
        <img
          className={styles.Image}
          src={imageUrl}
          alt={typeof title === "string" ? title : ""}
        />
      </div>
      <div className={styles.Heading}>
        <div className={styles.Title}>
          <span>{title}</span>
        </div>
        <div className={styles.Subtitle}>
          <span>{subtitle}</span>
        </div>
      </div>
      <Paragraph className={styles.Text}>{children}</Paragraph>
    </div>
  );
};

export default Avatar;
