import React from "react";
import { Button } from "../../../models/Button";
import { concatClasses } from "../../../utils/view/Concatinator";
import styles from "./Link.module.css";

const Link: typeof Button.Link = ({ className, active, ...props }) => {
  return (
    <button
      className={concatClasses(
        styles.Body,
        className,
        active === true && styles.Active
      )}
      {...props}
    />
  );
};

export default Link;
