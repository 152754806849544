import Primary from "../components/Button/Primary/Primary";
import Secondary from "../components/Button/Secondary/Secondary";
import { CaretLeft } from "phosphor-react";
import Link from "../components/Button/Link/Link";

type ButtonComponent = {
  Primary: React.FC<
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
      icon?: typeof CaretLeft | undefined;
    }
  >;
  Secondary: React.FC<
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
      icon?: typeof CaretLeft | undefined;
      animateIcon?: boolean | undefined;
      iconSize?: "sm" | "md" | "lg" | undefined;
    }
  >;
  Link: React.FC<
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
      active?: boolean | undefined;
    }
  >;
};

export const Button: ButtonComponent = {
  Primary,
  Secondary,
  Link,
};
