import { Pause, Play, SkipBack, SkipForward } from 'phosphor-react';
import React from 'react';
import { Button } from '../../../models/Button';
import styles from './Controls.module.css';

const AudioControls: React.FC<{
  isPlaying: boolean;
  isLoading: boolean;
  onPlayPauseClick: (val: boolean) => void;
  onPrevClick: () => void;
  onNextClick: () => void;
}> = ({ isPlaying, isLoading, onPlayPauseClick, onPrevClick, onNextClick }) => (
  <div className={styles.Controls}>
    <Button.Secondary disabled={isLoading} icon={SkipBack} iconSize="lg" onClick={onPrevClick} />
    <Button.Secondary
      disabled={isLoading}
      iconSize="lg"
      onClick={() => onPlayPauseClick(!isPlaying)}
      icon={!isLoading ? (isPlaying ? Pause : Play) : undefined}
    >
      {isLoading && <div className={styles.Loader} />}
    </Button.Secondary>
    <Button.Secondary disabled={isLoading} iconSize="lg" icon={SkipForward} onClick={onNextClick} />
  </div>
);

export default AudioControls;
