import React, { createContext, useEffect, useState } from "react";
import { agent } from "../api/agent";
import { defaultLocale } from "../global";

export const GlobalContext = createContext<
  | {
      activeElement: string;
      setActiveElement: React.Dispatch<React.SetStateAction<string>>;
      modalBody:
        | {
            title?: string | undefined;
            subtitle?: string | undefined;
            content: React.ReactNode;
          }
        | undefined;
      setModalBody: React.Dispatch<
        React.SetStateAction<
          | {
              title?: string | undefined;
              subtitle?: string | undefined;
              content: React.ReactNode;
            }
          | undefined
        >
      >;

      data: HomeDto | undefined;
      setData: React.Dispatch<React.SetStateAction<HomeDto | undefined>>;
      currentLang: DefaultLocales;
      handleLanguageChange(value: DefaultLocales): void;
    }
  | undefined
>(undefined);

export const GlobalContextProvider: React.FC = ({ children }) => {
  const [activeElement, setActiveElement] = useState<string>("home");
  const [modalBody, setModalBody] = useState<
    | {
        title?: string | undefined;
        subtitle?: string | undefined;
        content: React.ReactNode;
      }
    | undefined
  >();
  const [data, setData] = useState<HomeDto | undefined>();
  const [currentLang, setCurrentLang] = useState<DefaultLocales>(defaultLocale);

  useEffect(() => {
    !data &&
      agent.Application.GetHomeData().then((x) => {
        setData(x.data);
        handleInitialLanguage(x.data.languages);
        document.title = `${x.data.general.fullName} | Portfolio`;
        const metaThemeColor = document.querySelector("meta[name=theme-color]");
        metaThemeColor?.setAttribute("content", x.data.theme.accentColor);
      });
  }, [data]);

  const handleInitialLanguage = (languages: Language.Item[]): DefaultLocales => {
    const pathname = window.location.pathname;
    const locale = pathname.split("/")[1] as DefaultLocales | undefined;
    if (locale) {
      const langToSet = languages.find((x) => x.normalizedName === locale);
      if (langToSet) setCurrentLang(langToSet.normalizedName);

      return langToSet!.normalizedName;
    }
    return defaultLocale;
  };

  const handleLanguageChange = (value: DefaultLocales) => {
    // setCurrentLang(value);
    window.location.replace(`${window.location.origin}/${value}`);
  };

  return (
    <GlobalContext.Provider
      value={{
        activeElement,
        setActiveElement,
        modalBody,
        setModalBody,
        data,
        setData,
        currentLang,
        handleLanguageChange,
      }}
      children={children}
    />
  );
};
