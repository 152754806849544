import React, { useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import { locales } from "../../../locales/locales";
import { Card } from "../../../models/Card";
import { Heading } from "../../../models/Heading";
import { mapLocalizations } from "../../../utils/view/MapLocale";
import Section from "../../ui/Section/Section";
import styles from "./CareerSkills.module.css";
const CareerSkills = () => {
  const { data, currentLang } = useContext(GlobalContext)!;
  const { abilities, languages } = data!;
  return (
    <Section id="resume">
      <Heading.Primary
        title={locales[currentLang].Sections.GeneralSkills.title}
        subtitle={locales[currentLang].Sections.GeneralSkills.subtitle}
      />
      <div className={styles.Items}>
        {abilities
          .map((x) =>
            mapLocalizations({
              entity: x,
              languages,
              locale: currentLang,
            })
          )
          .map(({ id, title, content, percentage }) => (
            <Card.Progress key={id} title={title} percentage={percentage}>
              {content}
            </Card.Progress>
          ))}
      </div>
    </Section>
  );
};

export default CareerSkills;
