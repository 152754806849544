import { Heading } from "../../../models/Heading";
import { applyAnimation } from "../../../utils/view/ApplyAnimation";
import { concatClasses } from "../../../utils/view/Concatinator";
import styles from "./Primary.module.css";

const Primary: typeof Heading.Primary = ({ title, subtitle, className, ...props }) => {
  return (
    <div data-aos={applyAnimation("fade-down")} className={concatClasses(styles.Body, className)}>
      <h2 className={styles.Title}>
        <span>{title}</span>
      </h2>
      <div className={styles.Subtitle}>
        <span>{subtitle}</span>
      </div>
    </div>
  );
};

export default Primary;
