import { concatClasses } from "../../utils/view/Concatinator";
import styles from "./Preloader.module.css";
const Preloader: DivElement<{
  active: boolean;
}> = ({ className, active, ...props }) => {
  return (
    <div
      className={concatClasses(
        styles.Body,
        !active && styles.LoadingFinished,
        className
      )}
      {...props}
    >
      <div className={styles.Box}>
        <div className={styles.Centrized}>
          <div className={styles.Spinner}>
            <div className={styles.Lines}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
