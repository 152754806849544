import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/GlobalContext";
import { concatClasses } from "../../utils/view/Concatinator";
import styles from "./Modal.module.css";
import { XCircle, ArrowsOutSimple, ArrowsInSimple } from "phosphor-react";

const Modal: DivElement<{
  mainRef: React.RefObject<HTMLDivElement>;
}> = ({ className, mainRef, ...props }) => {
  const { modalBody, setModalBody } = useContext(GlobalContext)!;
  const [closing, setClosing] = useState<boolean>(false);
  const [enlarged, setEnlarged] = useState<boolean>(false);

  // useEffect(() => {
  //   if (modalBody && mainRef.current) {
  //     mainRef.current.style.overflowY = "hidden";
  //   } else if (!modalBody && mainRef.current)
  //     mainRef.current.style.overflowY = "unset";
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [modalBody]);

  const closeModal = () => {
    setModalBody(undefined);
    setClosing(false);
    setEnlarged(false);
  };

  useEffect(() => {
    if (closing) {
      const timer: NodeJS.Timeout = setTimeout(() => {
        closeModal();
      }, 300);
      return () => {
        clearTimeout(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closing]);

  const handleEsc = (event: KeyboardEvent) => {
    if (event.code === "Escape") {
      setClosing(true);
    }
  };
  useEffect(() => {
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  if (modalBody)
    return (
      <div className={styles.Wrapper}>
        <div
          className={concatClasses(
            styles.Content,
            className,
            closing && styles.Hide,
            enlarged && styles.EnlargeContent
          )}
          {...props}
        >
          <div className={styles.Heading}>
            <div className={styles.Text}>
              <span className={styles.Subtitle}>{modalBody.subtitle}</span>
              <h2 className={styles.Title}>{modalBody.title}</h2>
            </div>
            <div className={styles.IconSet}>
              <span className={styles.Enlarge}>
                {enlarged ? (
                  <ArrowsInSimple
                    onClick={() => setEnlarged(!enlarged)}
                    className={styles.Icon}
                  />
                ) : (
                  <ArrowsOutSimple
                    onClick={() => setEnlarged(!enlarged)}
                    className={styles.Icon}
                  />
                )}
              </span>
              <span className={styles.Close}>
                <XCircle
                  onClick={() => setClosing(true)}
                  className={styles.Icon}
                />
              </span>
            </div>
          </div>
          <div className={styles.Body}>{modalBody.content}</div>
        </div>
        <div onClick={() => setClosing(true)} className={styles.Overlay}></div>
      </div>
    );
  else return <></>;
};

export default Modal;
