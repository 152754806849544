import Primary from '../components/Card/Primary/Primary';
import Secondary from '../components/Card/Secondary/Secondary';
import Progress from '../components/Card/Progress/Progress';
import Step from '../components/Card/Step/Step';
import Avatar from '../components/Card/Avatar/Avatar';
import Image from '../components/Card/Image/Image';
import { CaretLeft } from 'phosphor-react';

type CardComponent = {
  Primary: DivElement<{
    subtitle: React.ReactNode;
    title: React.ReactNode;
  }>;
  Secondary: DivElement<{
    title: React.ReactNode;
    icon: typeof CaretLeft;
  }>;
  Progress: DivElement<{
    title: React.ReactNode;
    percentage: number;
  }>;
  Step: DivElement<{
    title: React.ReactNode;
    percentage: number;
  }>;
  Avatar: DivElement<{
    subtitle: React.ReactNode;
    title: React.ReactNode;
    imageUrl: string;
  }>;
  Image: DivElement<{
    subtitle: React.ReactNode;
    title: React.ReactNode;
    imageUrl: string;
    icon: typeof CaretLeft;
    active?: boolean | undefined;
  }>;
};

export const Card: CardComponent = {
  Primary,
  Secondary,
  Progress,
  Step,
  Avatar,
  Image,
};
