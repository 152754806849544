import { Card } from "../../../models/Card";
import { concatClasses } from "../../../utils/view/Concatinator";
import Paragraph from "../../Paragraph/Paragraph";
import styles from "./Progress.module.css";

const Progress: typeof Card.Progress = ({
  className,
  title,
  percentage,
  children,
  ...props
}) => {
  return (
    <div className={concatClasses(styles.Body, className)} {...props}>
      <div className={styles.Title}>
        <span>{title}</span>
      </div>
      <Paragraph>{children}</Paragraph>
      <div className={styles.ProgressTrack}>
        <div
          className={styles.ProgressBar}
          style={{
            width:
              percentage >= 0 && percentage <= 100 ? percentage + "%" : 0 + "%",
          }}
        >
          <span className={styles.Percentage}>{percentage}%</span>
        </div>
      </div>
    </div>
  );
};

export default Progress;
