import React, { useState, useEffect, useRef } from 'react';
import AudioControls from '../Controls/Controls';
import styles from './Player.module.css';
import { useAudioPlayerContext } from '../../../context/AudioPlayerContext';
/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
 */
const AudioPlayer: React.FC<{
  audioSrc: string | undefined;
  trackIndex: number | undefined;
}> = ({ audioSrc, trackIndex }) => {
  // State
  const { currentTrackIndex, setCurrentTrackIndex } = useAudioPlayerContext();
  const [trackProgress, setTrackProgress] = useState<number>(0);
  const [timeDisplay, setTimeDisplay] = useState<string>();

  // Refs
  const audioRef = useRef(new Audio(audioSrc));
  const intervalRef = useRef<any>();
  const isReady = useRef(false);

  const isPlaying = currentTrackIndex === trackIndex;

  function setIsPlaying(value: boolean) {
    if (value) setCurrentTrackIndex(trackIndex);
    else setCurrentTrackIndex(undefined);
  }

  useEffect(() => {
    // Create a new Audio object if audioSrc changes
    const audioTrack = audioRef.current;

    const updateTime = () => {
      if (audioTrack.duration && !isNaN(audioTrack.duration)) {
        const remainingTime = audioTrack.duration - audioTrack.currentTime;
        const minutes = Math.floor(remainingTime / 60).toFixed(0);
        const seconds = Math.floor(remainingTime % 60)
          .toFixed(0)
          .padStart(2, '0');
        setTimeDisplay(`${minutes}:${seconds}`);
      }
    };

    audioTrack.addEventListener('timeupdate', updateTime);

    // Cleanup
    return () => {
      audioTrack.removeEventListener('timeupdate', updateTime);
      clearInterval(intervalRef.current);
    };
  }, [audioSrc, isPlaying]);

  // Destructure for conciseness
  const { duration } = audioRef.current;

  audioRef.current.onended = (ev) => setIsPlaying(false);
  const currentPercentage = duration ? `${(trackProgress / duration) * 100}%` : '0%';
  const trackStyling = `
    -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  `;

  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        toNextTrack();
      } else {
        setTrackProgress(audioRef.current.currentTime);
      }
    }, 1000);
  };

  const onScrub = (value: number) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) setIsPlaying(true);
    startTimer();
  };

  const toPrevTrack = () => {
    setTrackProgress(0);
    audioRef.current.currentTime = 0;
  };

  const toNextTrack = () => {};

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  // Handles cleanup and setup when changing tracks
  useEffect(() => {
    audioRef.current.pause();

    audioRef.current = new Audio(audioSrc);
    setTrackProgress(audioRef.current.currentTime);

    if (isReady.current) {
      audioRef.current.play();
      setIsPlaying(true);
      startTimer();
    } else {
      // Set the isReady ref as true for the next pass
      isReady.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  const isLoading = !timeDisplay && isPlaying;
  return (
    <div className={styles.AudioPlayer}>
      <div className={styles.TrackInfo}>
        <h1 className={styles.TimeInfo}>{timeDisplay}</h1>
        <AudioControls
          isLoading={isLoading}
          isPlaying={isPlaying}
          onPrevClick={toPrevTrack}
          onNextClick={toNextTrack}
          onPlayPauseClick={setIsPlaying}
        />
        <input
          disabled={isLoading}
          type="range"
          value={trackProgress}
          step="1"
          min="0"
          max={duration ? duration : `${duration}`}
          className={styles.Track}
          onChange={(e) => onScrub(Number.parseInt(e.target.value))}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{ background: trackStyling }}
        />
      </div>
    </div>
  );
};

export default AudioPlayer;
