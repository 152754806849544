/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState } from "react";
import Anchor from "../../../../components/Anchor/Anchor";
import { GlobalContext } from "../../../../context/GlobalContext";
import { Button } from "../../../../models/Button";
import { Navbar } from "../../../../models/Navbar";
import { concatClasses } from "../../../../utils/view/Concatinator";
import styles from "./Desktop.module.css";
import { List } from "phosphor-react";
import { baseImageUrl } from "../../../../global";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import { locales } from "../../../../locales/locales";
import { applyAnimation } from "../../../../utils/view/ApplyAnimation";

const Desktop: typeof Navbar.Desktop = ({ ...props }) => {
  const { data, currentLang, handleLanguageChange } = useContext(GlobalContext)!;
  const { general, languages } = data!;

  const menuItems = [
    {
      text: locales[currentLang].Navigation.Home,
      link: "#home",
    },
    {
      text: locales[currentLang].Navigation.About,
      link: "#about",
    },
    {
      text: locales[currentLang].Navigation.Resume,
      link: "#resume",
    },
    {
      text: locales[currentLang].Navigation.Portfolio,
      link: "#portfolio",
    },
    {
      text: locales[currentLang].Navigation.Contacts,
      link: "#contacts",
    },
  ];

  const { activeElement } = useContext(GlobalContext)!;
  const [hamburgerMenuActive, setHamburgerMenuActive] = useState<boolean>(false);
  return (
    <header
      data-aos={applyAnimation("fade-down")}
      className={concatClasses(styles.Body, hamburgerMenuActive && styles.HamburgerMenuActive)}
      style={{
        backgroundColor: `${data?.theme.backgroundColor}CC`,
      }}
      {...props}
    >
      <div className={styles.Logo}>
        <a href="/">
          <img src={baseImageUrl + "Uploads\\Static\\Client\\logo.png"} alt="logo" />
          <span className={styles.LogoText}>{general.fullName}</span>
        </a>
      </div>
      <ul className={styles.MenuItems}>
        {menuItems.map((x, i) => (
          <li className={styles.MenuItem} key={i}>
            <Anchor
              className={styles.Anchor}
              active={activeElement === x.link.split("#")[1]}
              onClick={() => {
                document.querySelector(x.link)?.scrollIntoView({ behavior: "smooth", block: "center" });
                hamburgerMenuActive && setHamburgerMenuActive(false);
              }}
            >
              {x.text}
            </Anchor>
          </li>
        ))}
        <li className={concatClasses(styles.MenuItem)}>
          <Dropdown className={styles.Desktop} languages={languages} />
          <div className={styles.LanguageCollection}>
            {languages.map(({ normalizedName }) => (
              <a
                key={normalizedName}
                className={concatClasses(styles.LanguageItem, currentLang === normalizedName && styles.Active)}
                onClick={() => handleLanguageChange(normalizedName)}
              >
                {normalizedName}
              </a>
            ))}
          </div>
        </li>
      </ul>
      {general.documentUrl && (
        <Button.Primary
          onClick={() => window.open(baseImageUrl + general.documentUrl)}
          className={styles.DownloadButton}
        >
          {locales[currentLang].Buttons.DownloadCV}
        </Button.Primary>
      )}
      <Button.Secondary
        className={styles.HamburgerMenuButton}
        iconSize="lg"
        icon={List}
        onClick={() => setHamburgerMenuActive(!hamburgerMenuActive)}
      />
    </header>
  );
};

export default Desktop;
