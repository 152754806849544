import AZE from "../locales/aze.json";
import GER from "../locales/ger.json";
import ENG from "../locales/eng.json";

export const locales: {
  [P in DefaultLocales]: typeof AZE;
} = {
  aze: AZE,
  ger: GER,
  eng: ENG,
};
