import React from "react";
import { Pair } from "../../../models/Pair";
import { concatClasses } from "../../../utils/view/Concatinator";
import styles from "./Item.module.css";

const Item: typeof Pair.Item = ({
  className,
  headClassName,
  head,
  value,
  ...props
}) => {
  return (
    <li className={concatClasses(styles.Body, className)}>
      <strong className={concatClasses(styles.Head, headClassName)}>
        <span>{head}:</span>
      </strong>
      <span>{value}</span>
    </li>
  );
};

export default Item;
